// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-js": () => import("/opt/build/repo/src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("/opt/build/repo/src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-partenaires-js": () => import("/opt/build/repo/src/pages/partenaires.js" /* webpackChunkName: "component---src-pages-partenaires-js" */),
  "component---src-pages-politique-js": () => import("/opt/build/repo/src/pages/politique.js" /* webpackChunkName: "component---src-pages-politique-js" */),
  "component---src-pages-pourquoi-js": () => import("/opt/build/repo/src/pages/pourquoi.js" /* webpackChunkName: "component---src-pages-pourquoi-js" */),
  "component---src-pages-pricing-js": () => import("/opt/build/repo/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-produits-js": () => import("/opt/build/repo/src/pages/produits.js" /* webpackChunkName: "component---src-pages-produits-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

